.email-field-header {
  font-size: 16px;
  font-weight: 700;
  margin: 3em 0 1.5em 0;
}

.email-field-desc {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 3em; 
}